<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Invoice Number</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Invoice Number" rules="required">
                <b-form-input type="number" v-model="invoiceno" placeholder="Enter Invoice Number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Invoice Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Invoice Date" rules="required">
                <flat-pickr v-model="invoicedate" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),

                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Product Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Product Type" rules="required">
                <v-select v-model="producttype" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="['Goods', 'Service', 'Capital Goods']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Party Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Party Name">
                <v-select v-model="partyname" placeholder="None" :options="$store.state.master.vendors" label="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Currency</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Currency" rules="required">
                <b-form-input type="text" v-model="currency" placeholder="Enter Currency" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input type="text" v-model="remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Product" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 83cm">
                <thead class="text">
                  <tr>
                    <th style="width: 4cm">Sr no.</th>
                    <th style="width: 5cm">Work/Other</th>
                    <th style="width: 4cm">HSNCODE</th>
                    <th style="width: 4cm">Qty</th>
                    <th style="width: 4cm">UOM</th>
                    <th style="width: 4cm">Discount</th>
                    <th style="width: 4cm">Rate</th>
                    <th style="width: 4cm">Amount</th>
                    <th style="width: 4cm">CGST %</th>
                    <th style="width: 4cm">CGST Amount</th>
                    <th style="width: 4cm">SGST %</th>
                    <th style="width: 4cm">SGST Amount</th>
                    <th style="width: 4cm">IGST %</th>
                    <th style="width: 4cm">IGST Amount</th>
                    <th style="width: 4cm">CESS %</th>
                    <th style="width: 5cm">CESS</th>
                    <th style="width: 4cm">Net Amount</th>
                    <th style="width: 3cm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in data" :key="id">
                    <td>
                      {{ id + 1 }}
                    </td>
                    <td>
                      <validation-provider #default="{ errors }" rules="required" name="ledger">
                        <v-select v-model="item.ledgername" placeholder="None" label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="ledgerOption" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input v-model="item.hsncode" placeholder="Enter HSN Code" />
                    </td>
                    <td>
                      <b-form-input v-model="item.qty" placeholder="Enter QTY" @input="handleRate(id)" />
                    </td>
                    <td>
                      <v-select v-model="item.uom" placeholder="None"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="[
                          'CM ',
                          ' FEET ',
                          ' GMS',
                          ' KG ',
                          ' KM ',
                          ' KU ',
                          ' LITER ',
                          ' MG ',
                          ' MLT ',
                          ' MM ',
                          '  MTR ',
                          ' MU ',
                          ' NOS ',
                          ' QWE ',
                          ' SET  ',
                          '  SHEET',
                        ]" />
                    </td>

                    <td>
                      <v-select v-model="item.disc" placeholder="None"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="['RS', '%']"
                        @input="handleDisc(id)" />
                      <br />
                      <b-form-input v-model="item.discount_amount" placeholder="Enter discount"
                        @input="handleDisc(id)" />
                    </td>
                    <td>
                      <b-form-input v-model="item.rate" placeholder="Enter Rate" @input="handleRate(id)" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="item.amount" placeholder="Enter Amount" />
                    </td>
                    <td>
                      <b-form-input v-model="item.cgst" placeholder="Enter CGST%" @input="handleCgst(id)" />
                    </td>
                    <td>
                      <b-form-input v-model="item.cgstamount" disabled placeholder="Enter CGST" />
                    </td>
                    <td>
                      <b-form-input v-model="item.sgst" placeholder="Enter SGST%" @input="handleSgst(id)" />
                    </td>
                    <td>
                      <b-form-input v-model="item.sgstamount" disabled placeholder="Enter SGST" />
                    </td>
                    <td>
                      <b-form-input v-model="item.igst" placeholder="Enter IGST%" @input="handleIgst(id)" />
                    </td>
                    <td>
                      <b-form-input v-model="item.igstamount" disabled placeholder="Enter IGST" />
                    </td>
                    <td>
                      <b-form-input v-model="item.cessper" placeholder="Enter CESS%" />
                    </td>
                    <td>
                      <b-form-input v-model="item.cess" placeholder="Enter CESS" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="item.netamount" placeholder="Enter Net Amount" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" class="mt-0" @click="removeRow(id)">
                        <feather-icon icon="TrashIcon" class="" />
                        <!-- <span>Delete</span> -->
                      </b-button>
                    </td>
                  </tr>
                  <b-button variant="primary" @click="addRow" class="mt-1 ml-1 mb-1">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add More</span>
                  </b-button>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td>
                      <p class="label">P & F :</p>
                    </td>
                    <td>
                      <v-select v-model="pf.disc" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name" :options="['RS ']" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.rate" placeholder="Enter Rate" @input="handlePfRate()" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="pf.amount" placeholder="Enter Amount" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.cgst" placeholder="Enter CGST%" @input="handlepfCgst()" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="pf.cgstamount" placeholder="Enter CGST" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.sgst" placeholder="Enter SGST%" @input="handlepfSgst()" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.sgstamount" placeholder="Enter SGST" disabled />
                    </td>
                    <td>
                      <b-form-input v-model="pf.igst" placeholder="Enter IGST%" @input="handlepfIgst()" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.igstamount" placeholder="Enter IGST" disabled />
                    </td>
                    <td>
                      <b-form-input v-model="pf.cessper" placeholder="Enter CESS%" />
                    </td>
                    <td>
                      <b-form-input v-model="pf.cess" placeholder="Enter CESS" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="pf.netamount" placeholder="Enter Net Amount" />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <p class="label">Freight :</p>
                    </td>
                    <td>
                      <b-form-input v-model="freight.amount" placeholder="Enter amount"
                        @input="handleFreigntNet('amount')" />
                    </td>
                    <td>
                      <b-form-input v-model="freight.cgst" placeholder="Enter CGST%" @input="handlefreightCgst()" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="freight.cgstamount" placeholder="Enter CGST" />
                    </td>
                    <td>
                      <b-form-input v-model="freight.sgst" placeholder="Enter SGST%" @input="handlefreightSgst()" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="freight.sgstamount" placeholder="Enter SGST" />
                    </td>
                    <td>
                      <b-form-input v-model="freight.igst" placeholder="Enter IGST%" @input="handlefreightIgst()" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="freight.igstamount" placeholder="Enter IGST" />
                    </td>
                    <td>
                      <b-form-input v-model="freight.cessper" placeholder="Enter CESS%" />
                    </td>
                    <td>
                      <b-form-input v-model="freight.cess" placeholder="Enter CESS" />
                    </td>
                    <td>
                      <b-form-input disabled v-model="freight.netamount" placeholder="Enter Net Amount" />
                    </td>
                    <td></td>
                  </tr>
                  <tr></tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <p class="title">TDS Ledger</p>
                  </td>
                  <td>
                    <v-select v-model="tdsledger" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name" :options="ledgerOption" />
                  </td>
                  <td>
                    <b-form-input v-model="tdsnetamount" placeholder="Enter TDS Net Amount"
                      @input="handleGrandtotal()" />
                  </td>
                  <td></td>

                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <p class="title">Subtotal</p>
                    </td>
                    <td></td>
                    <td style="padding: 0">
                      <p class="title">Total CGST Amount</p>
                    </td>
                    <td></td>
                    <td style="padding: 0">
                      <p class="title">Total SGST Amount</p>
                    </td>
                    <td></td>
                    <td style="padding: 0">
                      <p class="title">Total IGST Amount</p>
                    </td>
                    <td></td>
                    <td>
                      <p class="title">Roundoff:</p>
                    </td>
                    <td>
                      <b-form-input disabled v-model="roundoff" />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td>
                      <p class="label">Total :</p>
                    </td>
                    <td>
                      <b-form-input disabled v-model="subtotal" />
                    </td>
                    <td></td>
                    <td>
                      <b-form-input disabled v-model="cgst" />
                    </td>
                    <td></td>
                    <td>
                      <b-form-input disabled v-model="sgst" />
                    </td>
                    <td></td>
                    <td>
                      <b-form-input disabled v-model="igst" />
                    </td>
                    <td></td>
                    <td>
                      <p class="title">Grand Total:</p>
                    </td>
                    <td>
                      <b-form-input disabled v-model="grandtotal" />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col md="1">
            <b-button class="ml-1 mt-2" variant="primary" type="submit" :disabled="submitDisable"
              @click.prevent="submitForm">
              Submit
            </b-button>
          </b-col>
          <b-col>
            <b-button class="mt-2 ml-4" variant="primary" @click="handleBack()">
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    flatPickr,
    ToastificationContent,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
  },
  data() {
    return {
      invoiceno: "",
      invoicedate: moment(new Date()).format("DD/MM/yyyy"),
      producttype: "",
      partyname: "",
      currency: "",
      remarks: "",
      pf: {
        rate: "",
        amount: 0,
        cess: "",
        cessamount: "",
        cessper: "",
        cgst: "",
        cgstamount: 0,
        disc: "",
        discount: 0,
        igst: "",
        igstamount: 0,
        netamount: 0,
        sgst: "",
        sgstamount: 0,
      },
      tdsledger: "",
      tdsnetamount: 0,
      grandtotal: "",
      roundoff: 0,
      igst: 0,
      sgst: 0,
      cgst: 0,
      subtotal: 0,
      freight: {
        amount: 0,
        cess: "",
        cessamount: "",
        cessper: "",
        cgst: "",
        cgstamount: 0,
        igst: "",
        igstamount: 0,
        netamount: 0,
        sgst: "",
        sgstamount: 0,
      },
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: "",
      data: [
        {
          ledgername: "",
          hsncode: "",
          qty: "",
          uom: "",
          rate: "",
          disc: "",
          discount_amount: "",
          amount: 0,
          cgst: "",
          cgstamount: 0,
          sgst: "",
          sgstamount: 0,
          igst: "",
          igstamount: 0,
          cessper: "",
          cess: "",
          netamount: 0,
        },
      ],
      ledgerOption: [],
      test: "",
      submitDisable: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Other Purchase") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/otherpurchase");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/otherpurchase");
          }
        }
      });
    }
    this.getLedger();
    this.$store.dispatch('master/getVendor')
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getotherpurchaseById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.data = [];
      this.invoiceno = item.invoiceno;
      this.invoicedate = item.invoicedate;
      this.producttype = item.producttype;
      this.partyname = item.partyname;
      this.currency = item.currency;
      this.remarks = item.remarks;
      this.pf = item.pf;
      this.freight = item.freight;
      this.grandtotal = parseInt(item.grandtotal);
      this.roundoff = item.roundoff;
      this.igst = item.igst;
      this.sgst = item.sgst;
      this.cgst = item.cgst;
      this.tdsnetamount = item.tdsnetamount;
      this.tdsledger = item.tdsledger;
      this.subtotal = item.subtotal;
      this.data = item.otherpurchase_details.map((item) => {
        item.ledgername = item.ledger;
        return item
      });
      // this.tdsledger = this.getLedger(item.tdsledger);
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success == true) {
          this.handleData();
          data = {
            invoiceno: this.invoiceno,
            invoicedate: this.invoicedate,
            producttype: this.producttype,
            partyname: this.partyname?.id,
            currency: this.currency,
            remarks: this.remarks,
            pf: this.pf,
            freight: this.freight,
            grandtotal: this.grandtotal,
            roundoff: this.roundoff,
            igst: this.igst,
            sgst: this.sgst,
            cgst: this.cgst,
            subtotal: this.subtotal,
            tdsledger: this.tdsledger?.id,
            tdsnetamount: this.tdsnetamount,
            data: this.data,
          };
        }

        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }

        if (success && this.data.length !== 0) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/otherpurchase/${this.$route.params.id}`
              : `${baseApi}/otherpurchase`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;
              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/otherpurchase");
            })
            .catch((error) => {
              this.submitDisable = false;
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleData() {
      if (this.data.length == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            variant: "danger",
            text: "Please Enter Other Purchase Details",
          },
        });
      } else {
        this.data.map((item) => {
          item.ledger_id = item.ledgername?.id
        });
      }
    },

    async getLedger(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/ledger`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.ledgerOption = response.data.data.data;
          if (id !== undefined) {
            this.ledgerOption.map((item) => {
              if (item.id == id) {
                this.tdsledger = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    addRow() {
      this.data.push({
        ledgername: "",
        hsncode: "",
        qty: "",
        uom: "",
        rate: "",
        disc: "",
        discount_amount: "",
        amount: 0,
        cgst: "",
        cgstamount: 0,
        sgst: "",
        sgstamount: 0,
        igst: "",
        igstamount: 0,
        cessper: "",
        cess: "",
        netamount: 0,
      });
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
    handleNet(index, name) {
      if (name == "amount") {
        this.handleSubtotal();
      } else if (name == "cgst") {
        this.handlecgsttotal();
      } else if (name == "sgst") {
        this.handlesgsttotal();
      } else if (name == "igst") {
        this.handleigsttotal();
      }
      this.data[index].netamount = (
        parseFloat(this.data[index].amount) +
        parseFloat(this.data[index].cgstamount) +
        parseFloat(this.data[index].sgstamount) +
        parseFloat(this.data[index].igstamount)
      ).toFixed(2);

      this.data[index].netamount = isNaN(this.data[index].netamount)
        ? 0
        : this.data[index].netamount;
      this.handleGrandtotal();
    },
    handlepfNet(name) {
      if (name == "amount") {
        this.handleSubtotal();
      } else if (name == "cgst") {
        this.handlecgsttotal();
      } else if (name == "sgst") {
        this.handlesgsttotal();
      } else if (name == "igst") {
        this.handleigsttotal();
      }
      this.pf.netamount = (
        parseFloat(this.pf.amount) +
        parseFloat(this.pf.cgstamount) +
        parseFloat(this.pf.sgstamount) +
        parseFloat(this.pf.igstamount)
      ).toFixed(2);
      this.handleGrandtotal();
    },
    handleFreigntNet(name) {
      if (name == "amount") {
        this.handleSubtotal();
        this.handlefreightCgst();
        this.handlefreightSgst();
        this.handlefreightIgst();
      } else if (name == "cgst") {
        this.handlecgsttotal();
      } else if (name == "sgst") {
        this.handlesgsttotal();
      } else if (name == "igst") {
        this.handleigsttotal();
      }
      this.freight.netamount = (
        parseFloat(this.freight.amount) +
        parseFloat(this.freight.cgstamount) +
        parseFloat(this.freight.sgstamount) +
        parseFloat(this.freight.igstamount)
      ).toFixed(2);
      this.handleGrandtotal();
    },
    handleSubtotal() {
      this.subtotal = 0;
      this.data.map((item) => {
        this.subtotal += parseFloat(item.amount);
      });
      this.subtotal += parseFloat(this.freight.amount);
      this.subtotal += parseFloat(this.pf.amount);

      this.subtotal = isNaN(this.subtotal) ? 0 : this.subtotal;
    },
    handlecgsttotal() {
      this.cgst = 0;
      this.data.map((item) => {
        this.cgst += parseFloat(item.cgstamount);
      });
      this.cgst += parseFloat(this.freight.cgstamount);
      this.cgst += parseFloat(this.pf.cgstamount);

      this.cgst = isNaN(this.cgst) ? 0 : this.cgst;
    },
    handlesgsttotal() {
      this.sgst = 0;
      this.data.map((item) => {
        this.sgst += parseFloat(item.sgstamount);
      });
      this.sgst += parseFloat(this.freight.sgstamount);
      this.sgst += parseFloat(this.pf.sgstamount);
      this.sgst = isNaN(this.sgst) ? 0 : this.sgst;
    },
    handleigsttotal() {
      this.igst = 0;
      this.data.map((item) => {
        this.igst += parseFloat(item.igstamount);
      });
      this.igst += parseFloat(this.freight.igstamount);
      this.igst += parseFloat(this.pf.igstamount);

      this.igst = isNaN(this.igst) ? 0 : this.igst;
    },
    handleGrandtotal() {
      this.tdsnetamount = this.tdsnetamount == "" ? 0 : this.tdsnetamount;
      this.grandtotal = 0;
      this.data.map((item) => {
        this.grandtotal += parseFloat(item.netamount);
      });
      this.grandtotal += parseFloat(this.freight.netamount);
      this.grandtotal += parseFloat(this.pf.netamount);
      this.grandtotal += parseFloat(this.tdsnetamount);

      this.roundoff = parseFloat(this.grandtotal) - Math.floor(parseFloat(this.grandtotal));
      this.roundoff = Math.round(this.roundoff * 100) / 100;
      this.grandtotal = parseInt(this.grandtotal);
      this.grandtotal = isNaN(this.grandtotal) ? 0 : this.grandtotal;
    },
    handleRate(id) {
      this.data[id].amount =
        parseFloat(this.data[id].qty) * parseFloat(this.data[id].rate);
      this.data[id].amount = isNaN(this.data[id].amount)
        ? 0
        : this.data[id].amount;
      this.handleDisc(id);
      this.handleNet(id, "amount");
      this.handleCgst(id);
      this.handleSgst(id);
      this.handleIgst(id);
    },
    handleDisc(id) {
      if (this.data[id].disc == "%") {
        this.data[id].amount =
          parseFloat(this.data[id].qty) * parseFloat(this.data[id].rate) -
          (parseFloat(this.data[id].discount_amount) *
            parseFloat(this.data[id].qty) *
            parseFloat(this.data[id].rate)) /
          100;
      } else if (this.data[id].disc == "RS") {
        this.data[id].amount =
          parseFloat(this.data[id].qty) * parseFloat(this.data[id].rate) -
          parseFloat(this.data[id].discount_amount);
      } else {
        this.data[id].amount =
          parseFloat(this.data[id].qty) * parseFloat(this.data[id].rate);
      }
      this.data[id].amount = isNaN(this.data[id].amount)
        ? 0
        : this.data[id].amount;
      this.handleNet(id, "amount");

      this.handleCgst(id);
      this.handleSgst(id);
      this.handleIgst(id);
    },
    handleCgst(id) {
      this.data[id].cgstamount =
        (this.data[id].amount * this.data[id].cgst) / 100;
      this.handleNet(id, "cgst");
    },
    handleIgst(id) {
      this.data[id].igstamount =
        (this.data[id].amount * this.data[id].igst) / 100;
      this.handleNet(id, "igst");
    },
    handleSgst(id) {
      this.data[id].sgstamount =
        (this.data[id].amount * this.data[id].sgst) / 100;
      this.handleNet(id, "sgst");
    },
    handlefreightCgst() {
      this.freight.cgstamount = (this.freight.amount * this.freight.cgst) / 100;
      this.handleFreigntNet("cgst");
    },
    handlefreightSgst() {
      this.freight.sgstamount = (this.freight.amount * this.freight.sgst) / 100;
      this.handleFreigntNet("sgst");
    },
    handlefreightIgst() {
      this.freight.igstamount = (this.freight.amount * this.freight.igst) / 100;
      this.handleFreigntNet("igst");
    },
    handlepfCgst() {
      this.pf.cgstamount = (this.pf.amount * this.pf.cgst) / 100;
      this.handlepfNet("cgst");
    },
    handlepfSgst() {
      this.pf.sgstamount = (this.pf.amount * this.pf.sgst) / 100;
      this.handlepfNet("sgst");
    },
    handlepfIgst() {
      this.pf.igstamount = (this.pf.amount * this.pf.igst) / 100;
      this.handlepfNet("igst");
    },

    handlePfRate() {
      this.pf.amount = this.pf.rate;
      this.handlepfCgst();
      this.handlepfSgst();
      this.handlepfIgst();
      this.handlepfNet("amount");
    },
    handleBack() {
      this.$router.push("/accounting/otherpurchase");
    },
  },
};
</script>

<style>
.label {
  font-size: 20px !important;
  float: right;
  margin: 0px;
}

.title {
  font-size: 15px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}
</style>
